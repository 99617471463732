@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

body
{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
}

body,html,#root
{
  min-height: 100vh;
}

.header
{
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: rgba($color: #FFF, $alpha: .95);
  left:0px;
  right:0px;
  margin-left:auto;
  margin-right: auto;
  height:50px;

  .container
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;

    .right
    {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
  }
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.3), 0 0 6px 2px rgba(0,0,0,0.15);

} 

.body
{
  padding-top: 60px;
  min-height: calc( 100vh - 80px );
}

.footer
{
  padding: .5rem;
  text-align:center;
  color:#ccc;
}

.center-box
{
  left: 0;
  right: 0;
  margin: 10% auto;
  width:fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-span
{
  display: flex;
  flex-direction: row;
  align-items: center;
  img.avatar
  {
    max-width:24px;
    max-height:24px;
    margin-right:.5rem;
    border-radius: 50%;
  }
  span.coin
  {
    margin-left: .2rem;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: .2rem;
  }
}

.recharge-page
{
  .price
  {
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-size: 36px;
  }
}

.profile-page
{
  .profile
  {
    text-align: center;
    .avatar
    {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: auto;
      img
      {
        border-radius: 50%;
        margin: 10px;
        max-width: 100px;
      }
    }
  }
  
  .detail
  {
    .row
    {
      width:50%;
      margin: 0px auto;
      margin-top:20px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding:10px;
      border-bottom: 1px solid #eee;
      
    }
  }
}

.nav-menu
{
  padding-left:1rem;
  padding-right:1rem;
  display: flex;
  align-items: center;
  height:50px;

  &.active
  {
    // background-color: rgba(252,211,77,.2);
    background-color: #eee;
  }
}


